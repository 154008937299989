
import { TOKEN_PREFIX } from "../config/config";

/**
 * assignAuthToken constant function (verify if token exists and set this to axios Authorization header and localStorage Token,
 * else, remove axios authorization header and localStorage Token)
 *
 * @export constant
 * @param {*} token
 * @returns boolean 
 */
export const assignAuthTokenAndRefreshToken = (tokenAndRefreshTokenJson) => {
  if (tokenAndRefreshTokenJson) {
    
    sessionStorage.setItem("jwtToken", TOKEN_PREFIX + tokenAndRefreshTokenJson.token);
    sessionStorage.setItem("refreshToken", tokenAndRefreshTokenJson.refreshToken);
    sessionStorage.setItem("encryptedToken",tokenAndRefreshTokenJson.encryptedToken);
  } else {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("refreshToken");
  }
};

export default assignAuthTokenAndRefreshToken;
