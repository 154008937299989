import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * PrimaryButtonUserCertificateBemyself constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "Center",
        width: "100%",
        "& .MuiButton-label": {
            fontWeight: "bold",
            textTransform: "none",
        },
    },
    button: {
        backgroundColor: `#25DFEB !important`,
        width: "100%",
        color: `#141D1E !important`,
        fontSize: "20px",
        textTransform: "none",
        borderRadius: "8px !important",
        border: `1px solid #25DFEB`,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
        "&.MuiButton-label": {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));
const PrimaryButtonUserCertificateBemyself = ({ ...props }) => {
    const { txtBtn, onClick, type, isPressImage, imgSrc } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button
                type={type === "" || type === undefined ? "submit" : type}
                className={classes.button}
                onClick={onClick}
                disabled={isPressImage}
            >
                {isPressImage && <CircularProgress size={14} color="inherit" />}
                {!isPressImage && (
                    <>
                        {txtBtn}
                        <img src={imgSrc} alt="btonBemyself" style={{ marginLeft: 5, marginBottom: 2 }} />
                    </>
                )}
            </Button>
        </div>
    );
};

export default PrimaryButtonUserCertificateBemyself;
