export const SET_APP_NAME = "GENERAL_SET_APP_NAME";
export const SET_AUTH_STATUS = "AUTH_SET_STATUS";
export const SET_USER_INFO = "AUTH_SET_USER_INFO";
export const AUTH_IS_FREE_TRIAL_APP = "AUTH_IS_FREE_TRIAL_APP";
export const IS_INVALID_TOKEN = "IS_INVALID_TOKEN";
export const SEND_ACTIVATION_EMAIL = "SEND_ACTIVATION_EMAIL";
export const IS_LOGIN_AUTOMATIC = "IS_LOGIN_AUTOMATIC";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_CHANGED = "GET_USER_CHANGED";
export const GET_USER_UPDATED = "GET_USER_UPDATED";
export const SET_CUSTOMERS_ERROR = "SET_CUSTOMERS_ERROR";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_DELETE = "SET_USER_DELETE";
export const SET_USER_ACTIVATED = "SET_USER_ACTIVATED";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const LOADING = "LOADING";
export const SET_USER_EXISTS_RESPONSE = "SET_USER_EXISTS_RESPONSE";
export const GET_USER_APPLICATIONS = "GET_USER_APPLICATIONS";

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const GET_ALL_APPLICATIONS_MENU = "GET_ALL_APPLICATIONS_MENU";
export const GET_ALL_APPLICATIONS_NOTIFICATION = "GET_ALL_APPLICATIONS_NOTIFICATION";
export const APPLICATION_CREATED = "APPLICATION_CREATED";
export const APPLICATION_MODIFIED = "APPLICATION_MODIFIED";
export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const APPLICATION_ACTIVATED = "APPLICATION_ACTIVATED";
export const GET_VERSION_APP = "GET_VERSION_APP";
export const SET_PARAMETER_APP = "SET_PARAMETER_APP"

export const GET_PRIVILEGE = "GET_PRIVILEGE";
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const SET_PRIVILEGE_ERROR = "SET_PRIVILEGE_ERROR";
export const GET_PRIVILEGE_CHANGED = "GET_PRIVILEGE_CHANGED";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLES = "GET_ROLES";
export const SET_ROLE_ERROR = "SET_ROLE_ERROR";
export const GET_ROLE_CHANGED = "GET_ROLE_CHANGED";
export const GET_ENTERPRISE = "GET_ENTERPRISE";
export const GENERATE_TOKENS = "GENERATE_TOKENS";

export const REVOKE_TOKENS = "REVOKE_TOKENS";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_BY_USER_LIST_RESPONSE = "GET_MENU_BY_USER_LIST_RESPONSE";
export const GET_MENU_APP_LIST = "GET_MENU_APP_LIST";
export const GET_MENU_CHANGE = "GET_MENU_CHANGE";
export const GET_MENU_IMAGE_CHANGE = "GET_MENU_IMAGE_CHANGE";
export const GET_MENU_ERROR_CHANGE = "GET_MENU_ERROR_CHANGE";

export const GET_PRIVILEGES_AUTH = "GET_PRIVILEGES_AUTH";
export const GET_MESSAGE_REFRESH_TOKEN = "GET_MESSAGE_REFRESH_TOKEN";
export const IS_REFRESH_TOKEN = "IS_REFRESH_TOKEN";

export const GET_MENU_USER_RESPONSE = "GET_MENU_USER_RESPONSE";
export const GET_MENU_TOP_RESPONSE = "GET_MENU_TOP_RESPONSE";
export const GET_MENU_LEFT_RESPONSE = "GET_MENU_LEFT_RESPONSE";
export const GET_SUITE_MENU_RESPONSE = "GET_SUITE_MENU_RESPONSE";

export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_ERROR_NOTIFICATION = "GET_ERROR_NOTIFICATION";
export const GET_ALL_SUBJECTS = "GET_ALL_SUBJECTS"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const CHANGE_NOTIFICATION = "CHANGE_NOTIFICATION"

export const GET_UPLOAD_FILE_REQUEST = "GET_UPLOAD_FILE_REQUES";
export const GET_UPLOAD_FILE_SUCCESS = "GET_UPLOAD_FILE_SUCCESS";
export const GET_UPLOAD_FILE_FAILURE = "GET_UPLOAD_FILE_FAILURE";

export const GET_TYPE_USER = "GET_TYPE_USER";

export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";

export const GET_EXISTS_USERS_ACCOUNT = "GET_EXISTS_USERS_ACCOUNT";

export const VALIDITY_USER_EMAIL = "VALIDITY_USER_EMAIL";

export const VALIDITY_EMAIL_CODE = "VALIDITY_EMAIL_CODE";

export const RESET_PASSWORD_USER_CERTIFICATE = "RESET_PASSWORD_USER_CERTIFICATE";

export const GET_UPLOAD_IMAGE_MENU = "GET_UPLOAD_IMAGE_MENU";

export const DEFAULTRESPONSE = {
  noResponseFromApi: "Error al realizar la petición.",
};

export const GET_MAINTENANCE_WINDOW = "GET_MAINTENANCE_WINDOW";
export const GET_USER_LIST = "GET_USER_LIST";
export const LOADING_GET_USERS_ACCOUNT = "LOADING_GET_USERS_ACCOUNT";
export const SET_PARAMS_CONNECT_CIAM_OIDC = "SET_PARAMS_CONNECT_CIAM_OIDC";
export const SET_TOKEN_OAUTH = "SET_TOKEN_OAUTH";
export const SET_ERROR_TOKEN_OAUTH = "SET_ERROR_TOKEN_OAUTH";
