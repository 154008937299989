import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/HighlightOff";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
//import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import "./alert.css";
import { CircularProgress, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
  },
  titleAlert: {
    color: theme.palette.secondary.dark,
    whiteSpace: "pre-line",
    // fontWeight: 700,
  },
  titleAlertReactivateUser: {
    color: "#575756",
    whiteSpace: "pre-line",
    // fontWeight: 700,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cancelButton: {
    marginRight: "31px",
  },
  dialogContent: {
    marginBottom: 20,
    textAlign: "center",
    fontSize: "14px",
  },
  dialogContentReactivateUser: {
    marginBottom: 20,
    textAlign: "justify",
    fontSize: "14px",
  },
  promoForm: {
    flexDirection: "row",
    border: "1px solid #F1F1F1",
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={10000} ref={ref} {...props} />;
  //console.log(props);
  // return <Fade in={true} timeout={10000} ref={ref} {...props} />;
});

const AlertConfirm = ({
  isOpen,
  isSecondStyle,
  onClose,
  modalTittle,
  modalMessage,
  textButtonAccept,
  showBtnCancel,
  showBtnAccept,
  handleAccept,
  showBtnClose,
  setOpen,
  textButtonCancel,
  customFunction,
  additionalButtons,
  isLoading,
  widthTitle = "302px",
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (!showBtnCancel && !showBtnAccept) {
      setTimeout(() => {
        if (typeof customFunction === "function") {
          customFunction();
        } else {
          setOpen(false);
        }
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog maxWidth="xs" open={isOpen} TransitionComponent={Transition}>
        <DialogTitle style={{ width: widthTitle }}>
          <Typography
            align="center"
            className={classes.titleAlert}
            margin="dense"
          >
            {modalTittle}
          </Typography>
          {showBtnClose && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            className={
              isSecondStyle === undefined
                ? classes.dialogContent
                : classes.dialogContentReactivateUser
            }
            style={{ color: theme.palette.secondary.main.main }}
          >
            <span
              className={
                isSecondStyle === undefined
                  ? classes.titleAlert
                  : classes.titleAlertReactivateUser
              }
            >
              {modalMessage}
            </span>
          </DialogContentText>
          {additionalButtons}
          {props.children}
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {showBtnCancel && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  className={classes.cancelButton}
                >
                  {textButtonCancel || "No"}
                </Button>
              )}

              {showBtnAccept && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAccept}
                >
                  {isLoading ? (
                    <CircularProgress size={25} color="secondary" />
                  ) : (
                    textButtonAccept || "Sí"
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AlertConfirm;
