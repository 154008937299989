import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "../views/dashboard/Home";
import Users from "../views/users/users.view";
import Login from "../views/auth/Login/Login";
import UserCertificate from "../views/auth/userCertificate/UserCertificate";
import LoginUko from "../views/auth/LoginUko/LoginUko";
import LoginUkoMyself from "../views/auth/LoginUkoMyself/LoginUko";
import ResetPassword from "../views/auth/Login/resetPassword.view";
import ResetPasswordUko from "../views/auth/LoginUko/ResetPasswordUko";
import ResetPasswordUkoMyself from "../views/auth/LoginUkoMyself/ResetPasswordUko";
import ResetPasswordCertificate from "../views/auth/userCertificate/resetPasswordCertificate.view";
import SendEmailResetPasswordUko from "../views/auth/LoginUko/SendEmailResetPasswordUko";
import SendEmailResetPasswordUkoMyself from "../views/auth/LoginUkoMyself/SendEmailResetPasswordUko";
import AbsentUkoMyself from "../views/auth/LoginUkoMyself/AbsentUko";
import ChangePassword from "../views/auth/Login/changePassword.view";
import ChangePasswordUko from "../views/auth/LoginUko/ChangePasswordUko";
import ChangePasswordUkoMyself from "../views/auth/LoginUkoMyself/ChangePasswordUko";
import Privileges from "../views/privileges/privileges.view";
import Roles from "../views/roles/roles.view";
import Applications from "../views/applications/Applications.view";
import ResetOtp from "../views/Otp/resetOtp.view";
import Alert from "../views/Alert";
import Logout from "../views/auth/Login/logout.view";
import MenuSettings from "../views/menuSettings/menusettings.view";
import InfoUko from "../views/auth/LoginUko/InfoUko";
import InfoUkoMyself from "../views/auth/LoginUkoMyself/InfoUko";
import { AvailableApps } from "../views/auth/Login/availableapps.view";
import { NotificationView } from "../views/notifications/notification.view";
import { LogoutFirmaya } from "../views/auth/Logout/LogoutFirmaya";
import PrivateRoute from "./PrivateRoute";
import { validateTokenAction, tokenValidation } from "../actions/auth.action";
import { parseJwt } from "../utils/userInfo";
import { PublicRoute } from "./PublicRoute";
import ResendEmailView from "../views/auth/Login/ResendEmailView";
import SelectAccountsUko from "../views/auth/LoginUkoMyself/SelectAccountsUko";
import CallBackBemyselfFirmaya from "../views/auth/loginOidc/CallBackBemyselfFirmaya";


let history = createBrowserHistory();

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */
class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      publicRoutes: [
        "/RESETPASSWORD",
        "/CHANGEPASSWORD",
        "/CHECK-EMAIL",
        "/RESETOTP",
        "/ALERT",
        "/INVALID-LINK",
        "/AVAILABLEAPPS",
        "/RESENDEMAIL",
        "/SELECTACCOUNTS",
        "/ABSENT",
        "/LOGOUT-CREDENTIAL",
        "/SIGNIN-CREDENTIAL/CALLBACK",
      ],
    };

    if (
      !this.state.publicRoutes.includes(history.location.pathname.toUpperCase())
    ) {
      const token = sessionStorage.getItem("jwtToken");
      if (token !== undefined && token !== null) {
        const { unique_name, APPS } = parseJwt(token);
        const betterToken = token.split("Bearer")[1].trim();
        this.props.tokenValidation(unique_name, betterToken, APPS, history, 1);
      } else {
        const encryptedToken = window.location.search.substring(1);
        const token = sessionStorage.getItem("jwtToken");
        if (encryptedToken === "" && token === null) {
          history.push({
            pathname: "/Login",
          });
        }
      }
    }
  }

  alertComponent = <Alert
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  loginComponent = <Login
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  LogoutFirmaya = <LogoutFirmaya
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  resetPasswordComponent = <ResetPassword
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  changePasswordComponent = <ChangePassword
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  userCertificateComponent = <UserCertificate
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  resetPasswordCertificateComponent = <ResetPasswordCertificate
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  loginUkoComponent = <LoginUko
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  changePasswordUkoComponent = <ChangePasswordUko
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  resetPasswordUkoComponent = <ResetPasswordUko
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  sendEmailResetPasswordUkoComponent = <SendEmailResetPasswordUko
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  infoUkoComponent = <InfoUko
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  //Myself components views

  loginUkoMyselfComponent = <LoginUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  changePasswordUkoMyselfComponent = <ChangePasswordUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  resetPasswordUkoMyselfComponent = <ResetPasswordUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  sendEmailResetPasswordUkoMyselfComponent = <SendEmailResetPasswordUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  AbsentUkoMyselfComponent = <AbsentUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  infoUkoMyselfComponent = <InfoUkoMyself
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth} />;

  resenEmailComponent = <ResendEmailView
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth}
  />

  selectAccountsComponent = <SelectAccountsUko appName={this.props.appName} {...this.props} />
  
  callBackBemyselfFirmaya = <CallBackBemyselfFirmaya
    {...this.props}
    history={history}
    isAuthed={this.props.isAuth}
  />

  getCommonApps(appName) {
    return {
      appname: appName,
      componentLogin: {
        component: this.loginComponent,
      },
      componentChangePassword: {
        component: this.changePasswordComponent,
      },
      componentResetPassword: {
        component: this.resetPasswordComponent,
      },
      componentSendEmailResetPassword: {
        component: this.alertComponent,
      },
      defaultAlert: {
        component: this.alertComponent,
      },
      componentCheckEmail: {
        component: this.alertComponent,
      },
      componentResendEmail: {
        component: this.resenEmailComponent,
      }
    }
  }

  getCertificateApps(appName) {
    return {
      appname: appName,
      componentLogin: {
        component: this.userCertificateComponent,
      },
      componentChangePassword: {
        component: this.changePasswordComponent,
      },
      componentResetPassword: {
        component: this.resetPasswordCertificateComponent,
      },
      componentSendEmailResetPassword: {
        component: this.alertComponent,
      },
      defaultAlert: {
        component: this.alertComponent,
      },
      componentCheckEmail: {
        component: this.alertComponent,
      },
      componentResendEmail: {
        component: this.resenEmailComponent,
      }
    };
  }

  getUkoTemplateApps(appName) {
    return {
      appname: appName,
      componentLogin: {
        component: this.loginUkoComponent,
      },
      componentChangePassword: {
        component: this.changePasswordUkoComponent,
      },
      componentResetPassword: {
        component: this.resetPasswordUkoComponent,
      },
      componentSendEmailResetPassword: {
        component: this.sendEmailResetPasswordUkoComponent,
      },
      defaultAlert: {
        component: this.infoUkoComponent,
      },
      componentCheckEmail: {
        component: this.sendEmailResetPasswordUkoComponent,
      },
      componentResendEmail: {
        component: this.infoUkoComponent,
      }
    };
  }

  getUkoMyselfTemplate(appName) {
    return {
      appname: appName,
      componentLogin: {
        component: this.loginUkoMyselfComponent,
      },
      componentChangePassword: {
        component: this.changePasswordUkoMyselfComponent,
      },
      componentResetPassword: {
        component: this.resetPasswordUkoMyselfComponent,
      },
      componentSendEmailResetPassword: {
        component: this.sendEmailResetPasswordUkoMyselfComponent,
      },
      defaultAlert: {
        component: this.infoUkoMyselfComponent,
      },
      componentCheckEmail: {
        component: this.sendEmailResetPasswordUkoMyselfComponent,
      },
      componentResendEmail: {
        component: this.infoUkoMyselfComponent,
      },
      componentSelectAccounts: {
        component: this.selectAccountsComponent,
      },
      componentAbsent: {
        component: this.AbsentUkoMyselfComponent,
      },
    };
  }

  /**
   * This array contains all aplications and each component asocited
   */
  routesConfig = ([
    this.getCommonApps('ibuhoo'),
    this.getCommonApps('license'),
    this.getCommonApps('colfactura'),
    this.getCommonApps('colnomina'),
    this.getCommonApps('biometria'),
    this.getCommonApps('confronta'),
    this.getCertificateApps('firmaya'),
    this.getUkoTemplateApps('myselfcert'),
    this.getUkoTemplateApps('letmicert'),
    this.getUkoMyselfTemplate('bemyself'),
  ]);

  /**
   * Seacrh in all compoents
   * @param {*} appnameToSearch current app could be firmaya
    * @param {*} componentSearching could be componentChangePassword
    * @returns asocited component or string empy
    */
  getComponentValue = (appnameToSearch, componentSearching) => {
    for (let i = 0; i < this.routesConfig.length; i++) {
      const currentElement = this.routesConfig[i];
      if (currentElement.appname === appnameToSearch) {
        return currentElement[componentSearching];
      }
    }
    return '';
  };

  /**
   * Search component with current app extracted from props
   * @param {*} componentToSearch
    * @returns Component or string empty
    */
  searchComponent = (componentToSearch) => {
    const currentAppName = this.props.appName;
    const searchedComponent = this.getComponentValue(currentAppName, componentToSearch);
    if (searchedComponent !== undefined) {
      return searchedComponent.component;
    } else {
      history.push({
        pathname: "/Login",
      });
    }
    return (<div></div>);
  }

  render() {

    return (
      <BrowserRouter >
        <Router history={history}>
          <Switch>
            <PublicRoute
              path="/selectaccounts"
              exact
              component={() => {
                return this.searchComponent('componentSelectAccounts');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/resetpassword"
              exact
              component={() => {
                return this.searchComponent('componentResetPassword');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/resetpasswordCertificate"
              exact
              component={() => {
                return this.searchComponent('componentResetPassword');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/changepassword"
              exact
              component={() => {
                return this.searchComponent('componentChangePassword');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/login"
              exact
              isAuth={this.state.isAuth}
              component={() => {
                return this.searchComponent('componentLogin');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/"
              exact
              isAuth={this.state.isAuth}
              component={() => {
                return this.searchComponent('componentLogin');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/check-email"
              exact
              component={() => {
                if (history.location.state?.previousTabHistory === '/Alert') {
                  return this.searchComponent('defaultAlert');
                }
                return this.searchComponent('componentCheckEmail');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/resetotp"
              exact
              component={(props) => (
                <ResetOtp {...this.props} history={history} isAuthed={true} />
              )}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/logout"
              exact
              component={Logout}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/alert"
              exact
              component={() => {
                return this.searchComponent('defaultAlert');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/availableapps"
              exact
              component={AvailableApps}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/resendemail"
              exact
              component={() => {
                return this.searchComponent('componentResendEmail');
              }}
              appName={this.props.appName}
            />
            <PrivateRoute
              path="/"
              exact
              component={Home}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/home"
              exact
              component={Home}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/users"
              exact
              component={Users}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/privileges"
              exact
              component={Privileges}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/roles"
              exact
              component={Roles}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/apps"
              exact
              component={Applications}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/menuSettings"
              exact
              component={MenuSettings}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/notifications"
              exact
              component={NotificationView}
              history={history}
              {...this.props}
            />
            <PublicRoute
              path="/absent"
              exact
              component={() => {
                return this.searchComponent('componentAbsent');
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/logout-credential"
              exact
              component={() => {
                return this.LogoutFirmaya
              }}
              appName={this.props.appName}
            />
            <PublicRoute
              path="/signin-credential/callback"
              exact
              component={() => {
                return this.callBackBemyselfFirmaya
              }}
              appName={this.props.appName}
            />
          </Switch>
        </Router>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuth: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, {
  validateTokenAction,
  tokenValidation,
})(Routes);