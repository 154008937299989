import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  createRole,
  modifyRole,
  getApplicationById,
} from "../../actions/roles.action";
import { makeStyles } from "@material-ui/core/styles";
import PrivilegeApps from "./privilegesapp.view";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormLayout from "../../components/layouts/FormLayout";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import ButtonApps from "../../components/ButtonForm/ButtonApps.component";
import { validateEquals, isEmpty } from "../../utils/proprietaryHooks";
import { ButtonSaveChanges } from "../../components/ButtonForm/ButtonSaveChanges";
import TabComponent from "../../components/TabsComponent/TabComponent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import {
  GET_PRIVILEGES,
  GET_ROLE,
  GET_ROLE_CHANGED,
} from "../../actions/types";
import { messagesResponseRoles } from "../../utils/constants";

/**
 * RoleForm Component ( full view for form to create/modify role )
 *
 * @export Class Component
 * @class RoleForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    margin: "0px 15px",
  },
  tabsItem: {
    textTransform: "none",
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "3px 0px",
    flex: 1,
    float: "right",
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
}));

const RoleForm = (props) => {
  const classes = useStyles();
  /* #region  Props */
  const {
    roleId,
    rol,
    setRoleId,
    modifyRole,
    createRole,
    setCardState,
    updateChecked,
    setShowRoleAlert,
    getApplicationById,
    getRoleChangedResponse,
    getApplicationsResponse,
    setLoading,
    setRoleErrorResponse,
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [viewErrors, setErrors] = useState("");
  const [allRoles, setAllRoles] = useState(false);
  const [charactersToEnd] = useState(
    !!rol.description ? 100 - rol.description.length : 100
  );
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const [enabledTabsForm, setEnabledTabsForm] = useState(
    !isEmpty(rol) ? false : true
  );
  const initialStateForm = {
    id: "",
    name: "",
    description: "",
  };
  const [formRoleData, setFormRoleData] = useState(
    !isEmpty(rol)
      ? {
          id: rol.id,
          name: rol.name,
          description: rol.description ?? '',
          alias: rol.alias ?? '',
        }
      : initialStateForm
  );
  const [privilegeData, setPrivilegeData] = useState([]);
  const [privilegeCounter, setPrivilegeCounter] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState("");
  /* #endregion */

  /* #region  useRef */
  const validators = useRef({
    isNewRol: roleId === 0 ? true : false, // Valida si es un nuevo rol
  });
  const originalInfo = useRef({
    originalData: formRoleData,
    originalPrivileges: [],
  });
  const originalAppIsVisible = useRef([]);
  const appsIsVisible = useRef([]);
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().max(100).required(requiredMessage),
    description: Yup.string(),
    alias: Yup.string().max(100),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { ...formRoleData },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  //#endregion

  const dispatch = useDispatch();

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    if (validators.current.isNewRol) {
      await trigger();
      if (!isEmpty(formState.errors)) {
        return;
      }
      setTab(newTab);
      if (newTab === 1) {
        if (!privilegeData[selectedAppId]) {
          setLoading(true);
        }
      }
    } else if (enabledTabsForm) {
      if (!compareObjects(tab)) {
        showMessageSaveOrUpdate({
          newTab: newTab,
          changeTab: true,
          cancelEdit: false,
          back: false,
        });
      } else {
        setTab(newTab);
        if (newTab === 1) {
          if (!privilegeData[selectedAppId]) {
            setLoading(true);
          }
        }
      }
    } else {
      setTab(newTab);
      if (newTab === 1) {
        if (!privilegeData[selectedAppId]) {
          setLoading(true);
        }
      }
    }
  };

  const handleAppRole = (id) => {
    if (id !== selectedAppId) {
      setSelectedAppId(id);
      checkAllRolesSelected(id);
      setErrors("");
      setLoading(true);
      if (!!privilegeData[id]) {
        const rolCount = privilegeData[id].filter(
          (item) => item.assigned
        ).length;
        setPrivilegeCounter(
          privilegeCounter.map((item) =>
            item.name === id
              ? { counter: rolCount, name: item.name, title: item.title }
              : item
          )
        );
      }
    }
  };

  const saveRole = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validators.current.isNewRol) {
      setErrors("");
      await trigger();
      if (isEmpty(formState.errors)) {
        const [roleSaveData, countPrivAssign] = createObjectSave(true);
        if (countPrivAssign <= 0) {
          setErrors("*Se requiere mínimo un privilegio");
          setLoading(false);
          return;
        } else {
          setErrors("");
        }
        confirmSaveChanges(roleSaveData);
      }
    } else {
      const [roleSaveData, countPrivAssign] = createObjectSave(
        tab === 0 ? true : false
      );
      if (tab === 1) {
        if (countPrivAssign <= 0) {
          setErrors("*Se requiere mínimo un privilegio");
          setLoading(false);
          return;
        } else {
          setErrors("");
        }
      }
      confirmSaveChanges(roleSaveData);
    }
  };

  const confirmSaveChanges = (roleSaveData) => {
    if (validators.current.isNewRol) {
      createRole(roleSaveData, updateChecked);
    } else {
      modifyRole(roleSaveData, updateChecked, false, tab);
    }
  };

  const NotChange = () => {
    dispatch({
      type: "GET_ROLE_CHANGED",
      payload: messagesResponseRoles.noChanges,
    });
  };

  const createObjectSave = (includeForm) => {
    let roleSaveData = JSON.parse(
      JSON.stringify(includeForm ? { ...getValues() } : { id: getValues().id })
    );

    const getvaluesDescription = getValues().description;
    roleSaveData.description = getvaluesDescription;

    let counterPrivilegeSelected = 0;
    if (validators.current.isNewRol || !includeForm) {
      roleSaveData.application = [];
      Object.keys(privilegeData).forEach((appId) => {
        let privApp = privilegeData[appId];
        let listPrivileges = [];
        privApp.forEach((priv) => {
          if (priv.assigned) {
            listPrivileges.push(priv.name);
          }
        });
        roleSaveData.application.push({
          name: appId,
          privilege: listPrivileges,
          isVisible:
            appsIsVisible.current.find((x) => x.name === appId).isVisible ||
            false,
        });
      });
      counterPrivilegeSelected = privilegeCounter.filter(
        (app) => app.counter > 0
      ).length;
    }

    return [roleSaveData, counterPrivilegeSelected];
  };

  const compareObjects = (tab) => {
    setLoading(true);
    let response = false;
    switch (tab) {
      case 0:
        response = validateEquals(
          { ...getValues() },
          originalInfo.current.originalData
        );
        break;
      case 1:
        const responseData = validateEquals(
          privilegeData,
          originalInfo.current.originalPrivileges
        );
        const responseIsVisible = validateEquals(
          { isVisible: appsIsVisible.current },
          { isVisible: originalAppIsVisible.current }
        );
        if (responseData && responseIsVisible) {
          response = true;
        }
        break;
      default:
        break;
    }
    setLoading(false);
    return response;
  };

  const backButton = () => {
    if (tab === 0) {
      if (validators.current.isNewRol) {
        resetFieldComplete();
      } else if (enabledTabsForm) {
        if (!compareObjects(0)) {
          showMessageSaveOrUpdate({
            back: true,
            newTab: 0,
            changeTab: false,
            cancelEdit: false,
          });
        } else {
          resetFieldComplete();
        }
      } else {
        resetFieldComplete();
      }
    } else {
      if (validators.current.isNewRol) {
        setTab(0);
      } else if (enabledTabsForm) {
        if (!compareObjects(1)) {
          showMessageSaveOrUpdate({
            back: true,
            newTab: 0,
            changeTab: false,
            cancelEdit: false,
          });
        } else {
          setTab(0);
        }
      } else {
        setTab(0);
      }
    }
  };

  useEffect(() => {
    return () => {
      resetFieldComplete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roleId !== 0) {
      getApplicationById(roleId);
    } else {
      getApplicationById(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  useEffect(() => {
    if (!Array.isArray(setRoleErrorResponse)) {
      setLoading(false);
      let response = isEmpty(setRoleErrorResponse.result)
        ? ""
        : setRoleErrorResponse.result.information;
      setErrors(response);
    }
  }, [setLoading, setRoleErrorResponse]);

  useEffect(() => {
    if (!Array.isArray(getRoleChangedResponse)) {
      setCardState(true);
      setShowRoleAlert(true);
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
        item: "inactivate",
      });
    }

    if (!!getRoleChangedResponse) {
      if (getRoleChangedResponse === messagesResponseRoles.createRole) {
        resetFieldComplete();
      }

      if (getRoleChangedResponse === messagesResponseRoles.updateRoleData) {
        originalInfo.current.originalData = JSON.parse(
          JSON.stringify({ ...getValues() })
        );
        activeValidations();
      }

      if (getRoleChangedResponse === messagesResponseRoles.updateRoleApps) {
        if (
          !confirmInactivate.changeTab &&
          !confirmInactivate.back &&
          !confirmInactivate.cancelEdit
        ) {
          resetFieldComplete();
        } else {
          originalInfo.current.originalPrivileges = JSON.parse(
            JSON.stringify(privilegeData)
          );
          originalAppIsVisible.current = JSON.parse(
            JSON.stringify(appsIsVisible.current)
          );
          activeValidations();
        }
      }

      if (
        getRoleChangedResponse === messagesResponseRoles.noChanges &&
        tab === 1
      ) {
        resetFieldComplete();
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleChangedResponse]);

  useEffect(() => {
    setDefaultApp();
    setLoading(false);
    appsIsVisible.current = getApplicationsResponse.map((item) => ({
      name: item.name,
      isVisible: item.isVisible,
      title: item.title,
    }));
    originalAppIsVisible.current = getApplicationsResponse.map((item) => ({
      name: item.name,
      isVisible: item.isVisible,
      title: item.title,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationsResponse]);

  const checkAllRolesSelected = (appId) => {
    if (
      Object.keys(privilegeData).length > 0 &&
      privilegeData[appId] !== undefined
    ) {
      const assignedByApp = privilegeData[appId].filter(
        (item) => item.assigned === true
      );
      if (assignedByApp.length === privilegeData[appId].length) {
        setAllRoles(true);
      } else {
        setAllRoles(false);
      }
    } else {
      setAllRoles(false);
    }
  };

  const resetFieldComplete = () => {
    setRoleId(0);
    setPrivilegeData([]);
    setAllRoles(false);
    setTab(0);
    setConfirmInactivate((state) => ({ ...state, newTab: -1 }));
    setCardState(false);
    setFormRoleData(initialStateForm);
    originalInfo.current = {};
    setLoading(false);
    setConfirmInactivate({
      open: false,
    });
    dispatch({
      type: GET_ROLE,
      payload: [],
    });
    dispatch({
      type: GET_PRIVILEGES,
      payload: [],
    });
    dispatch({
      type: GET_ROLE_CHANGED,
      payload: [],
    });
  };

  const handleChangeChecked = (event, name, rowName) => {
    if (event.target.checked === true) {
      setErrors("");
    }
    const add = event.target.checked ? 1 : -1;
    const rolCount =
      privilegeData[selectedAppId].filter((item) => item.assigned === true)
        .length + add;
    setPrivilegeCounter([
      ...privilegeCounter.map((item) =>
        item.name === selectedAppId ? { ...item, counter: rolCount } : item
      ),
    ]);
    let indexPriv = privilegeData[selectedAppId].findIndex(
      (role) => role.name === rowName
    );
    privilegeData[selectedAppId][indexPriv].assigned = event.target.checked;
    checkAllRolesSelected(selectedAppId);
  };

  const handleAllRolesChecked = (event) => {
    if (event.target.checked === true) {
      setErrors("");
    }
    setPrivilegeCounter([
      ...privilegeCounter.map((item) =>
        item.name === selectedAppId
          ? {
              ...item,
              counter: event.target.checked
                ? privilegeData[selectedAppId].length
                : 0,
            }
          : item
      ),
    ]);
    for (const currentPrivilege of privilegeData[selectedAppId]) {
      currentPrivilege.assigned = event.target.checked;
    }
    setAllRoles(event.target.checked);
  };

  const setDefaultApp = async () => {
    if (roleId === 0) {
      const defaultApp = getApplicationsResponse[0];
      if (defaultApp !== undefined) {
        setSelectedAppId(defaultApp.name);
      }
    } else {
      if (!!getApplicationsResponse.length) {
        const mainApp = getApplicationsResponse.reduce((prev, curr) =>
          prev.privilegeCounter > curr.privilegeCounter ? prev : curr
        );
        setSelectedAppId(mainApp.name);
      }
    }
    setPrivilegeCounter(
      getApplicationsResponse.map((item) => ({
        name: item.name,
        counter: item.privilegeCounter,
        title: item.title,
      }))
    );
  };

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ),
      showBtnAccept: true,
      showBtnCancel: true,
    });
  };

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
    });
  };

  const activeValidations = () => {
    if (confirmInactivate.changeTab) {
      if (tab === 0) {
        if (confirmInactivate.newTab === 1) {
          if (!privilegeData[selectedAppId]) {
            setLoading(true);
          }
        }
      }
      setTab(confirmInactivate.newTab);
    }

    if (confirmInactivate.back) {
      if (tab === 0) {
        resetFieldComplete();
      } else {
        setTab(0);
      }
    }

    if (confirmInactivate.cancelEdit) {
      setEnabledTabsForm(false);
    }
  };

  const handleCancel = () => {
    if (tab === 0) {
      for (const key in formRoleData) {
        setValue(key, originalInfo.current.originalData[key]);
      }
    } else {
      setPrivilegeData(
        JSON.parse(JSON.stringify(originalInfo.current.originalPrivileges))
      );
      appsIsVisible.current = JSON.parse(
        JSON.stringify(originalAppIsVisible.current)
      );
      const counter = getCounterPrivileges(
        JSON.parse(JSON.stringify(originalInfo.current.originalPrivileges))
      );
      setPrivilegeCounter([
        ...privilegeCounter.map((privilege) =>
          counter.some((count) => count.name === privilege.name)
            ? {
                title: privilege.title,
                ...counter.find((count) => count.name === privilege.name),
              }
            : privilege
        ),
      ]);
    }
    activeValidations();
    closeAlertDefault();
  };

  const handleAccept = (e) => {
    saveRole(e);
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      if (!compareObjects(tab)) {
        showMessageSaveOrUpdate({
          cancelEdit: true,
          newTab: 0,
          changeTab: false,
          back: false,
        });
      } else {
        setEnabledTabsForm(response);
      }
    } else {
      setEnabledTabsForm(response);
    }
  };

  /**
   *
   * @param {*} privilegesInfo  informacion de privilegios a ser contados
   * @returns {Array} privilegeCounter
   */
  const getCounterPrivileges = (privilegesInfo) => {
    let privilegeCounter = [];
    for (const key in privilegesInfo) {
      const privileges = privilegesInfo[key].filter(
        (privilege) => privilege.assigned === true
      ).length;
      privilegeCounter.push({ name: key, counter: privileges });
    }
    return privilegeCounter;
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (!validators.current.isNewRol) {
      switch (tab) {
        case 0:
          subheader = enabledTabsForm ? "Editar rol" : "Detalles del rol";
          break;
        case 1:
          subheader = enabledTabsForm
            ? "Editar aplicaciones"
            : "Detalles de aplicaciones";
          break;
        default:
          subheader = "Nuevo rol";
          break;
      }
    } else {
      subheader = "Nuevo rol";
    }
    return subheader;
  };

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheader={defineSubHeader()}
        isEditing={!validators.current.isNewRol}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar este rol?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"roles tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={[
            {
              label: "Datos",
              tabContent: (
                <>
                  <Grid item lg={10} md={10} sm={10}>
                    <ControlledInputRoundedForm
                      id="name"
                      name="name"
                      inputProps={{ maxLength: 100 }}
                      label="Nombre Rol"
                      fullWidth
                      control={control}
                      pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú_\s]/g}
                      disabled={!enabledTabsForm}
                      error={errors.name}
                      helperText={errors.name?.message}
                    />
                    <ControlledInputRoundedForm
                      id="description"
                      name="description"
                      label="Descripción"
                      multiline
                      rows={5}
                      inputProps={{ maxLength: 100 }}
                      helperText={charactersToEnd}
                      fullWidth
                      disabled={!enabledTabsForm}
                      control={control}
                    />
                    <ControlledInputRoundedForm
                      id="alias"
                      name="alias"
                      label="Alias"
                      rows={5}
                      inputProps={{ maxLength: 100 }}
                      helperText={'\u00A0'} 
                      pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s]/g}
                      fullWidth
                      disabled={!enabledTabsForm}
                      control={control}
                    />
                  </Grid>
                  {!!roleId ? (
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      justifyContent="center"
                    >
                      <ButtonSaveChanges
                        title="Actualizar Datos"
                        isDisabled={!enabledTabsForm}
                        handleClick={(e) => {
                          if (!compareObjects(0)) {
                            handleSubmit(saveRole(e));
                          } else {
                            NotChange();
                          }
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    direction="row-reverse"
                  >
                    <Tooltip
                      id="button-next"
                      title="Siguiente"
                      style={{ float: "right", marginTop: "18px" }}
                    >
                      <IconButton onClick={(e) => handleChangeTab(e, 1)}>
                        <ArrowIcon className={classes.nextArrow} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              ),
            },
            {
              label: "Aplicaciones",
              tabContent: (
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="stretch"
                >
                  <Grid item lg={4} md={4} sm={12}>
                    {privilegeCounter.map((row, index) => {
                      if (row.name.toUpperCase() !== "RESETOTP") {
                        const isVisible = appsIsVisible.current.find(
                          (item) => item.title === row.title
                        ).isVisible;
                        return (
                          <ButtonApps
                            isActive={selectedAppId === row.name}
                            key={index}
                            onClick={() => handleAppRole(row.name)}
                            fullWidth
                            title={row.title}
                            appsIsVisible={appsIsVisible}
                            checked={
                              isVisible !== undefined ? isVisible : false
                            }
                            globlaDisabled={!enabledTabsForm}
                            viewSwitch={true}
                            appCounter={`${
                              !!row.counter ? row.counter : 0
                            } privilegios`}
                          />
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </Grid>
                  <Grid item lg={8} md={8} sm={12}>
                    <PrivilegeApps
                      appId={selectedAppId}
                      roleId={roleId}
                      privilegeData={privilegeData}
                      setPrivilegeData={setPrivilegeData}
                      allRoles={allRoles}
                      setAllRoles={setAllRoles}
                      checkRoles={checkAllRolesSelected}
                      setErrors={setErrors}
                      handleAllRolesChecked={handleAllRolesChecked}
                      handleChangeChecked={handleChangeChecked}
                      apps={getApplicationsResponse}
                      setLoading={setLoading}
                      globalDisabled={!enabledTabsForm}
                      originalInfo={originalInfo}
                      setPrivilegeCounter={setPrivilegeCounter}
                      privilegeCounter={privilegeCounter}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {viewErrors !== "" && (
                      <div className={classes.errorText}>
                        <Typography color="error" variant="subtitle2">
                          {viewErrors}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} style={{ marginTop: 20 }}>
                    <ButtonSaveChanges
                      id="buttonSave"
                      style={classes.buttonSave}
                      margin="dense"
                      title={!formRoleData.id ? "Guardar" : "Actualizar"}
                      isDisabled={!enabledTabsForm}
                      handleClick={(e) => {
                        if (validators.current.isNewRol) {
                          handleSubmit(saveRole(e));
                        } else {
                          if (!compareObjects(1)) {
                            handleSubmit(saveRole(e));
                          } else {
                            NotChange();
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />
      </FormLayout>
    </div>
  );
};

RoleForm.propTypes = {
  createRole: PropTypes.func.isRequired,
  modifyRole: PropTypes.func.isRequired,
  getRoleById: PropTypes.func.isRequired,
  getApplicationById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modifyRole: state.rolesReducer.modifyRole,
  createRole: state.rolesReducer.createRole,
  getRoleResponse: state.rolesReducer.getRoleResponse,
  getRoleChangedResponse: state.rolesReducer.getRoleChangedResponse,
  getApplicationsResponse: state.rolesReducer.getApplicationsResponse,
  setRoleErrorResponse: state.rolesReducer.setRoleErrorResponse,
});

const mapDispatchToProps = {
  createRole,
  modifyRole,
  getApplicationById,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
