import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageBackground from "../../../components/pageBackground.component";
import { CircularProgress,useTheme } from "@material-ui/core";

export const LogoutFirmaya = () => {
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/login");
    }, 2000); 
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <div className="login">
      <div className="card_login">
        <PageBackground />
        
        <div style={{ textAlign: "center", alignContent: "center", marginTop: "5px" }}>
          <CircularProgress size={65} thickness={6} style={{color:theme.colorLoading}} />
        </div>
      </div>
    </div>
  );
};