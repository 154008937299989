import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * PrimaryButtonUserCertificate constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "Center",
    width: "100%",
    "& .MuiButton-label": {
      fontWeight: "bold",
    },
  },
  button: {
    backgroundColor: `${theme.palette.common.white} !important`,
    width: "100%",
    color: `${theme.palette.primary.main} !important`,
    fontSize: "20px",
    textTransform: "none",
    borderRadius: "8px !important",
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonCertificate: {
    backgroundColor: theme.palette.primary.main,
    height: "40px",
    width: "50%",
    color: theme.palette.common.white,
    fontSize: "20px",
    textTransform: "none",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonCertificateEnter: {
    backgroundColor: theme.palette.primary.main,
    height: "38px",
    width: "100%",
    color: theme.palette.common.white,
    fontSize: "20px",
    textTransform: "none",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#D87B3C",
    },
  },
}));
const PrimaryButtonUserCertificate = ({ ...props }) => {
  const { txtBtn, onClick, type, loading, name, nameComponent, disabled } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        name={name}
        type={type === "" || type === undefined ? "submit" : type}
        className={nameComponent && nameComponent === "resetPasswordCertificate" && txtBtn === "Continuar" ? classes.buttonCertificateEnter : txtBtn === "Continuar" || txtBtn === "Entendido" ? classes.buttonCertificate : txtBtn === "Ingresar" || txtBtn === "Enviar" ? classes.buttonCertificateEnter : classes.button}
        onClick={onClick}
        disabled={loading || disabled}
      >
        {loading && <CircularProgress size={14} color="inherit" />}
        {!loading && txtBtn}
      </Button>
    </div>
  );
};

export default PrimaryButtonUserCertificate;
