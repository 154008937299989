import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    useTheme,
    Grid,
    Box,
    makeStyles,
    Card,
} from "@material-ui/core";
import FullLoader from "../../../components/Loader/FullLoader.component";
import { getVersionAppAction } from "../../../actions/applications.action";
import PrimaryButtonUserCertificate from "../../../components/primaryButtonUserCertificate.component";
import { GenerateTokenOIDCAction } from "../../../actions/auth.action";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "574px",
        height: "",
        margin: "0 auto",
        padding: "25px",
        borderRadius: 12,
    },
    logo: {
        width: "147px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: "46px",
        paddingTop: "1.64%",
        backgroundImage: theme.logo.backgroundImage,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const CallBackBemyselfFirmaya = (props) => {
    const {
        appName,
        tenant,
        GenerateTokenOIDC,
        loadingReducer,
        setTokenAuth,
        setErrorTokenAuth,
    } = props;

    const [error, setError] = useState(false);
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        if (code) {
            GenerateTokenOIDC(code, tenant, appName);
        } else {
            setError(true);
        }
    }, [tenant, appName, GenerateTokenOIDC]);

    useEffect(() => {
        if (setTokenAuth) {
            if (setTokenAuth.response && setTokenAuth.statusCode === 200 && setTokenAuth.response.informationCode === 'A10') {
                window.location.assign(setTokenAuth.response.urlApp);
            }
        }
    }, [setTokenAuth]);

    useEffect(() => {
        if (setErrorTokenAuth) {
            if (setErrorTokenAuth.message) {
                setError(true);
            }
        }
    }, [setErrorTokenAuth, setTokenAuth]);

    return (
        <>
            <Grid container style={{ minHeight: "100vh" }}>
                <Grid item md={12} xs={12} style={{
                    backgroundImage: theme.pageBackground.backgroundImage, backgroundSize: "cover",
                    backgroundRepeat: "no-repeat", backgroundPosition: "center", display: "flex",
                    justifyContent: "center", flexDirection: "column", alignItems: "center"
                }}>
                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        {
                            error && (
                                <Card className={classes.root}>
                                    <Box style={{ textAlign: "center", maxWidth: "550px", width: "100%", padding: "15px" }}>
                                        <Box style={{ marginBottom: '50px' }}>
                                            <div className={classes.logo} alt="Logo"></div>
                                        </Box>
                                        <Box style={{ marginBottom: '70px' }}>
                                            <img src={`/${theme.urlLogo?.urlSuccessAlertlogo}`} alt="alert_image" style={{ width: "113px", height: "101px"}} />
                                        </Box>
                                        <Box>
                                            <h1 style={{ fontWeight: "700", fontStyle: 'normal', fontSize: "24px", marginTop: "20px", color: theme.palette.primary.main }}>
                                                {"¡Lo sentimos!"}
                                            </h1>
                                        </Box>
                                        <Box style={{ marginBottom: '30px' }}>
                                            <h1 style={{ fontWeight: "400", fontStyle: 'normal', fontSize: "18px", marginTop: "20px", color: '#4F4F4F', }}>
                                                {"En este momento no hemos podido verificar tu credencial, Por favor inténtalo más tarde."}
                                            </h1>
                                        </Box>
                                        <Box style={{ paddingRight: '88px', paddingLeft: '88px' }}>
                                            <PrimaryButtonUserCertificate
                                                txtBtn={"Entendido"}
                                                loading={false}
                                                onClick={() => {
                                                    history.replace('/Login');
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    {/* </Box> */}
                                </Card>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
            <FullLoader open={loadingReducer.loading} />
        </>
    )
};

CallBackBemyselfFirmaya.propTypes = {
};

const mapStateToProps = (state) => ({
    loadingReducer: state.loadingReducer,
    setTokenAuth: state.authReducer.setTokenAuth,
    setErrorTokenAuth: state.authReducer.setErrorTokenAuth,
});

const mapDispatchToProps = {
    GenerateTokenOIDC: GenerateTokenOIDCAction,
    getVersionAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallBackBemyselfFirmaya);

