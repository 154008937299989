import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import {
  makeStyles,
  TextField,
  withStyles,
  InputAdornment,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { Rules } from '../../../utils/RHFRules';
import ErrorBox from "../../../components/errorBoxCertificate.component";
import { getDocumentTypes, getExistsUsersAccount, loginUser, generateTokens, clearAuthenticationResponse, ConnectCiamOidcAction } from "../../../actions/auth.action";
import { getEnterprise } from "../../../actions/enterprise.action";
import { getParmeterConfigAction } from "../../../actions/applications.action";
import { SET_IS_AUTHENTICATED, LOADING_GET_USERS_ACCOUNT } from "../../../actions/types";
import PageBackground from "../../../components/pageBackground.component";
import CardBody from "../../../components/CardBody/cardBody.component";
import FullLoader from "../../../components/Loader/FullLoader.component";
import PrimaryButtonUserCertificate from "../../../components/primaryButtonUserCertificate.component";
import PrimaryButtonUserCertificateBemyself from "../../../components/PrimaryButtonUserCertificateBemyself.component";
import MaintenanceView from "../../notifications/MaintenanceView";

let isVerified = false
let tryFailedByRecaptcha = 0;

const UserCertificate = (props) => {
  const {
    appName,
    getDocumentTypes,
    documentTypes,
    isAuthenticated,
    getExistsUsersAccount,
    getAuthenticationResponse,
    getParmeterConfigAction,
    parameterConfig,
    generateTokensResponse,
    loginUser,
    clearAuthenticationResponse,
    ConnectCiamOidcAction,
    setParamsConnectCiamOidc,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [typeDocumentPass, setTypeDocumentPass] = useState(0);
  const [errorsApp, setErrorsApp] = useState();
  const [messageAttempts, setMessageAttempts] = useState("");
  const [paramsCiamConnect, setParamsCiamConnect] = useState("");
  const [isPressImage, setIsPressImage] = useState(false);
  const [blocked, setBlocked] = useState({
    timeStatus: "",
    timeBlocked: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [loadingFull, setLoadingFull] = useState(false);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    defaultValues: ({
      typeDocument: "",
      numberDocument: "",
      passwordCertificate: "",
    }),
    shouldUnregister: false,
  });
  const classes = useStyles();
  const theme = useTheme();

  //#region

  useEffect(() => {
    if (isAuthenticated) {
      //window.location.assign(isAuthenticated.urlAuth);
    }
    if (appName && appName !== "") {
      clearAuthenticationResponse();
      setLoading(true);
      getParmeterConfigAction(appName, 'ParameterApps');
      ConnectCiamOidcAction(appName);
      isVerified = false;
      tryFailedByRecaptcha = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
    getDocumentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        setLoading(false);
        setLoadingFull(true);
        reset();
      } else {
        setLoading(false);
        setErrorsApp(getAuthenticationResponse.message);
        reset();
      }

      if (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") {
        setTimeout(() => {
          window.location.assign(getAuthenticationResponse.url);
        }, 2000);
      }

      if (getAuthenticationResponse.isBlocked !== undefined && getAuthenticationResponse.isBlocked) {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
        setMessageAttempts(`Para solucionarlo, comunícate al ${getAuthenticationResponse.messageParameter}`);
      }

      if (getAuthenticationResponse.failedLoginInformation !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts === 1) {
        setBlocked({
          timeBlocked: "",
          timeStatus: false,
        });
      }

      if (getAuthenticationResponse.code === "A20") {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
      }
    }

    if (!!generateTokensResponse) {
      if (generateTokensResponse.informationCode === "A10" || generateTokensResponse.informationCode === "A20") {
        window.location.assign(generateTokensResponse.urlApp);
        dispatch({
          action: SET_IS_AUTHENTICATED,
          payload: {
            authenticated: true,
            urlAuth: generateTokensResponse.urlApp,
          },
        });
      }
    }
  }, [getAuthenticationResponse, generateTokensResponse, reset, dispatch]);

  useEffect(() => {
    if (setParamsConnectCiamOidc) {
      if (setParamsConnectCiamOidc.response) {
        setParamsCiamConnect(setParamsConnectCiamOidc.response.ciamOidcUri);
      }
    }
  }, [setParamsConnectCiamOidc]);
  //#endregion

  //#region  custom methods

  const onChange = (value) => {
    if (value) {
      isVerified = true;
      tryFailedByRecaptcha = 0;
    } else {
      isVerified = false;
      tryFailedByRecaptcha = 0;
    }
  }

  const setLogin = async (data) => {
    let userData = {
      documentType: data.typeDocument,
      documentNumber: data.numberDocument,
      password: data.passwordCertificate,
      appname: appName,
      page: window.location.search.substring(1),
    };
    if (parameterConfig && parameterConfig.captchaApply) {
      if (isVerified && tryFailedByRecaptcha === 0) {
        setLoading(true);
        loginUser(userData, history);
      } else {
        tryFailedByRecaptcha = 1;
        clearAuthenticationResponse();
      }
    } else {
      setLoading(true);
      loginUser(userData, history);
    }
  };

  const getRemainingTime = (deadline) => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2);
    return {
      remainSeconds,
      remainMinutes,
      remainHours,
      remainTime,
    };
  };

  const ClockCountsDown = (finaMinute) => {
    if (!!getAuthenticationResponse && !getAuthenticationResponse.isBlocked) {
      let FechaHora = "";
      let fechaActual = new Date();
      let faltanMinutos = finaMinute * 1000;
      let fechaFuturo = fechaActual.getTime() + faltanMinutos;
      if (blocked.timeStatus !== true) {
        let timerUpdate = setInterval(() => {
          let currentTime = getRemainingTime(new Date(fechaFuturo));
          FechaHora = currentTime.remainMinutes + ":" + currentTime.remainSeconds;
          setBlocked({
            timeBlocked:
              "El usuario se encuentra bloqueado, vuelve a intentarlo en " +
              FechaHora,
            timeStatus: true,
          });
          setMessageAttempts("Si olvidaste la contraseña, puedes dar clic en la opción \"Recordar contraseña\"");
          if (currentTime.remainTime <= 0) {
            clearInterval(timerUpdate);
            setBlocked({
              timeBlocked:
                "El usuario ya fue liberado por favor intente acceder de nuevo.",
              timeStatus: false,
            });
            setMessageAttempts("");
          }
        }, 1000);
      }
    }
  };

  const getErrors = () => {
    return !!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation
      ? (<>
        {getAuthenticationResponse.responseStat === "R13"
          ? errorsApp &&
          (blocked.timeBlocked === ""
            ? ClockCountsDown(
              getAuthenticationResponse.failedLoginInformation.lockTime
            )
            : "",
            (
              <ErrorBox
                value={
                  <>
                    {blocked.timeBlocked === "" ? errorsApp : blocked.timeBlocked}
                    {messageAttempts !== "" && <br></br>}
                    {messageAttempts !== "" && <br></br>}
                    {blocked.timeBlocked !== "" && (messageAttempts !== "" && messageAttempts)}
                  </>
                }
                appName={appName}
              ></ErrorBox>
            ))
          : getAuthenticationResponse.responseStat !== "R21"
            ? errorsApp &&
            (getAuthenticationResponse.responseStat === "R11" &&
              getAuthenticationResponse.failedLoginInformation.failedAttempts >
              0 ? (
              <ErrorBox
                value={
                  <>
                    {errorsApp}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? "tienes dos (" : "tienes solo un ("}
                    {!!getAuthenticationResponse && 3 - getAuthenticationResponse.failedLoginInformation.failedAttempts}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? ") intentos más." : ") intento más."}
                  </>
                }
                appName={appName}
              ></ErrorBox>
            ) : (
              <ErrorBox value={errorsApp} appName={appName} ></ErrorBox>
            ))
            : !!errorsApp && <ErrorBox value={errorsApp} appName={appName}></ErrorBox>}
      </>
      ) : (!!getAuthenticationResponse &&
        (getAuthenticationResponse.responseStat === "R21"
          || getAuthenticationResponse.responseStat === "R22"
          || getAuthenticationResponse.responseStat === "R23"
        )) && <ErrorBox value={getAuthenticationResponse.message} appName={appName}></ErrorBox>;
  };

  /**
   *  returns error box of auth response
   * @returns {JSX.Element}
   */

  const getErrorRecaptcha = () => {
    if (!isVerified && tryFailedByRecaptcha > 0) {
      return <ErrorBox
        value={"Por favor valida que no eres un robot"}></ErrorBox>
    }
  }

  const setTypeDocument = (event) => {
    setValue("numberDocument", "");
    if (event.target.value === "PA") {
      setTypeDocumentPass(1);
    } else {
      setTypeDocumentPass(0);
    }
    clearErrors("typeDocument");
  };

  const onLinkGoToResetPassword = async (e) => {
    dispatch({ type: LOADING_GET_USERS_ACCOUNT, payload: false });
    clearErrors("passwordCertificate");
    let hasError = false;
    const getValueTypeDocument = getValues("typeDocument");
    const getValueNumberDocument = getValues("numberDocument");
    if (getValueNumberDocument) clearErrors("numberDocument");
    if (!getValueTypeDocument) {
      setError("typeDocument", {
        type: "manual",
        message: "Campo obligatorio",
      });
      hasError = true;
    }
    if (!getValueNumberDocument) {
      setError("numberDocument", {
        type: "manual",
        message: "Campo obligatorio",
      });
      hasError = true;
    }
    if (hasError) {
      e.preventDefault();
      return;
    }
    clearErrors("typeDocument");
    let validationRule;
    if (typeDocumentPass === 0) {
      validationRule = Rules.numerico;
    } else {
      validationRule = Rules.letrasNumeros;
    }
    const patternTest = validationRule.pattern.value.test(getValueNumberDocument);
    if (!patternTest) {
      e.preventDefault();
      return setError("numberDocument", {
        type: "manual",
        message: validationRule.pattern.message,
      });
    }
    clearErrors("numberDocument");
    let userData = {
      typeDocument: getValueTypeDocument,
      numberDocument: getValueNumberDocument,
    };
    await getExistsUsersAccount(userData);
  };

  const handlePressButtonBemyself = () => {
    setIsPressImage(true);
    if (paramsCiamConnect) {
      clearErrors();
      clearAuthenticationResponse();
      window.location.href = paramsCiamConnect;
    }
  };

  //#endregion

  return (
    <>
      <div className="login">
        <div className={classes.formControl}>
          <PageBackground></PageBackground>
          <CardBody>
            <form id="user-certificate" autoComplete="off">
              <fieldset disabled={isVisible ? true : false}>
                <hr></hr>
                <br />
                <Controller
                  control={control}
                  placeholder="Tipo de documento"
                  name="typeDocument"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onChange={() => {
                    clearErrors("typeDocument");
                  }}
                  rules={Rules.select}
                  error={!!errors.typeDocument}
                  helperText={errors.typeDocument ? errors.typeDocument.message : null}
                  FormHelperTextProps={{ style: { marginLeft: 0 } }}
                  as={(
                    <TextField
                      select
                      size="small"
                      variant="outlined"
                      required
                      fullWidth
                      className={classes.error}
                      InputProps={{
                        classes,
                        startAdornment: (
                          <div style={{ height: "35.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
                            <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                          </div>
                        ),
                      }}
                    >
                      {documentTypes && documentTypes.map(
                        (td) => (
                          <StyledMenuItem
                            key={td.id}
                            value={td.code}
                          >
                            {td.name}
                          </StyledMenuItem>
                        ),
                      )}
                    </TextField>
                  )}
                  inputProps={{ onChange: (e) => setTypeDocument(e) }}
                />
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="numberDocument"
                  control={control}
                  placeholder="Número de documento"
                  fullWidth={true}
                  onChange={() => {
                    clearErrors("numberDocument");
                  }}
                  error={!!errors.numberDocument}
                  helperText={errors.numberDocument ? errors.numberDocument.message : null}
                  rules={typeDocumentPass === 0 ? Rules.numerico : Rules.letrasNumeros}
                  InputProps={{
                    classes,
                    startAdornment: (
                      <div style={{ height: "34.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", marginTop: "5px" }}>
                        <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                      </div>
                    ),
                  }}
                />
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="passwordCertificate"
                  control={control}
                  placeholder="Contraseña del certificado"
                  fullWidth={true}
                  onChange={() => {
                    clearErrors("passwordCertificate");
                  }}
                  error={!!errors.passwordCertificate}
                  type={showPassword ? "text" : "password"}
                  helperText={errors.passwordCertificate ? errors.passwordCertificate.message : null}
                  rules={Rules.password}
                  InputProps={{
                    classes,
                    startAdornment: (
                      <div style={{ height: "35.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", marginTop: "5px" }}>
                        <LockIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                      </div>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {!showPassword ? (
                            <VisibilityIcon fontSize="small"></VisibilityIcon>
                          ) : (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {parameterConfig && !parameterConfig.captchaApply && (
                  <br></br>
                )}
                {parameterConfig && parameterConfig.captchaApply && (
                  <div>
                    <ReCAPTCHA className="recaptcha"
                      sitekey="6Lc675cpAAAAAA9QU8AecdiDpFLP1TD8Ht1hpmf1"
                      onChange={onChange}
                      size="normal"
                    />
                  </div>
                )}
                {(isVerified) && <br />}
                {getErrorRecaptcha()}
                {getErrors()}
                <br />
                <PrimaryButtonUserCertificate
                  txtBtn={"Ingresar"}
                  loading={loading}
                  onClick={handleSubmit(setLogin)}
                />
                <br />
                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                  <Link
                    onClick={onLinkGoToResetPassword}
                    to="/resetpasswordCertificate"
                    style={{
                      color: theme.palette.primary.dark,
                      textDecoration: "underline",
                    }}
                  >
                    Olvidé mi contraseña
                  </Link>
                </Typography>
                <div style={{ paddingTop: "10px" }}></div>
                <Box position="relative" width="100%" my={2}>
                  <hr></hr>
                  <Typography
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      padding: "0 10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: "-10px",
                      color: "#B2B2B2",
                    }}
                  >
                    ó
                  </Typography>
                </Box>
                <div style={{ paddingTop: "10px" }}></div>
                <PrimaryButtonUserCertificateBemyself
                  txtBtn={"Ingresar con "}
                  onClick={handlePressButtonBemyself}
                  type={"button"}
                  imgSrc={"/botonBemyself.svg"}
                  isPressImage={(paramsCiamConnect && !isPressImage) ? false : true}
                />
                {loadingFull && <FullLoader open={loadingFull} />}
              </fieldset>
            </form>
          </CardBody>
        </div>
      </div>
      <MaintenanceView appName={appName} setIsVisible={setIsVisible} isVisible={isVisible}></MaintenanceView>
    </>
  );
};

UserCertificate.propTypes = {
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
  getEnterprise: PropTypes.func.isRequired,
  enterpriseReducer: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  enterpriseReducer: state.enterpriseReducer,
  isAuthenticated: state.authReducer.isAuthenticated,
  documentTypes: state.authReducer.documentTypes,
  getUsersAccount: state.authReducer.getUsersAccount,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  parameterConfig: state.applicationsReducer.parameterConfig,
  generateTokensResponse: state.authReducer.generateTokensResponse,
  setParamsConnectCiamOidc: state.authReducer.setParamsConnectCiamOidc,
});
export default connect(mapStateToProps, {
  getEnterprise,
  getDocumentTypes,
  getExistsUsersAccount,
  getParmeterConfigAction,
  loginUser,
  generateTokens,
  clearAuthenticationResponse,
  ConnectCiamOidcAction,
})(UserCertificate);

const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: "500",
    color: "#B2B2B2",
    paddingLeft: 6,
    fontFamily: "muli, saint-serif",
    fontSize: "18px",
    "&:hover": {
      color: "#4F4F4F",
      fontWeight: "400",
      border: "none",
    },
    borderRadius: "0px 12px 12px 0px",
    marginTop: 4,
  },
  root: {
    paddingLeft: "0px",
    boxShadow: "0px 4px 3px #00000033",
    borderRadius: "12px",
    backgroundColor: "white",
    height: "36.38px",
    "& .MuiInputBase-root": {
      color: theme.palette.primary.light,
    },
    "& .MuiFormLabel-root": {
      color: "black",
      paddingLeft: "40px",
    },
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-nativeInput": {
      left: "34px",
      width: "100%",
      bottom: "0",
      color: "transparent",
      opacity: 0.3,
      position: "absolute",
      border: "none",
      pointerEvents: "none",
      fontSize: "18px",
      marginBottom: "7px",
    },
    "& .MuiSelect-nativeInput::placeholder": {
      color: "#4F4F4F",
    },
    "& .MuiSelect-icon": {
      color: "#F5AE19",
    },
  },
  error: {
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00000033",
    },
  },
  formControl: {
    maxHeight: "97vh",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
  },
}));

const StyledMenuItem = withStyles({
  root: {
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#878787",
        color: "#FFFFFF",
      },
    },
    "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected": {
      backgroundColor: "#878787",
      color: "#FFFFFF",
    },
  },
})(MenuItem);
