export const Rules = {
    numerico: {
        required: 'Campo obligatorio',
        pattern: {
          value: /^[0-9]{5,15}$/,
          message: 'El campo debe ser numérico, mínimo 5 caracteres.',
        },
    },
    letrasNumeros: {
      required: 'Campo obligatorio',
      pattern: {
        value: /^[a-zA-Z0-9]{5,15}$/,
        message: 'El campo debe ser alfanumérico, mínimo 5 caracteres.',
      },
    },
    select: {
      required: 'Campo obligatorio',
    },
    password: {
      required: 'Campo obligatorio',
    },
  };