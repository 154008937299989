import axios from "axios";
import {
  API_AUTHENTICATION,
  encryptionKey,
  encryptionIv,
  TIME_REFRESH_TOKEN,
  API_SECURITY_ENDPOINT,
  defaultHeaders,
} from "../config/config";
import {
  SET_CURRENT_USER,
  LOADING,
  DEFAULTRESPONSE,
  GENERATE_TOKENS,
  REVOKE_TOKENS,
  SET_IS_AUTHENTICATED,
  GET_ERRORS,
  GET_USER_DATA,
  IS_INVALID_TOKEN,
  SEND_ACTIVATION_EMAIL,
  IS_LOGIN_AUTOMATIC,
  GET_DOCUMENT_TYPES,
  GET_EXISTS_USERS_ACCOUNT,
  VALIDITY_USER_EMAIL,
  VALIDITY_EMAIL_CODE,
  RESET_PASSWORD_USER_CERTIFICATE,
  GET_USER_LIST,
  LOADING_GET_USERS_ACCOUNT,
  SET_PARAMS_CONNECT_CIAM_OIDC,
  SET_TOKEN_OAUTH,
  SET_ERROR_TOKEN_OAUTH
} from "./types";
import jwt_decode from "jwt-decode";
import assignAuthTokenAndRefreshToken from "../utils/assignAuthToken";

import aesjs from "aes-js";
import moment from "moment";
import "moment/locale/es";
import { getBrowserInfo } from "../utils/proprietaryHooks";
import { getComponentValue } from "../routes/routesConfig";
import { encryptAes } from "../utils/crypo";

// const userLoginInfo = async (data) => {
//   const response = await axios.put(API_AUTHENTICATION + "/api/UserLoginInfo", data)
//   if (response.data.statusCode === 200) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const CHECKEMAIL = 'CHECK_EMAIL';
export const SENDEMAIL = 'SEND_EMAIL';
export const ERRORINFO = 'INFO_ERROR';
export const PASSWORDUPDATED = 'PASSWORD_UPDATED';
export const ASSIGNINITIALPASSWORD = 'ASSIGN_INITIAL_PASSWORD';

export /**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {*} userData
 */
  const loginUser = (userData, history, tenant = '', messageActionCheckWelcome = '', messageActionPasswordHasExpired = '') => async (dispatch) => {
    let datadispatch = {};
    changeLoading(dispatch, true);
    const infoNavigator = getBrowserInfo();

    //Cifra contenido de password
    var passwordEncrypt = encryptAes(userData.password);
    userData.password = passwordEncrypt;

    let tenantLanguage = '';
    if (userData.appname === 'license' && tenant !== null && tenant !== undefined && tenant !== '') {
      tenantLanguage = localStorage.getItem("bms_lang");
      if (!tenantLanguage) {
        tenantLanguage = "es";
      } else {
        tenantLanguage = JSON.parse(tenantLanguage);
      }
      tenantLanguage = tenantLanguage.toUpperCase();
    }
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      tenant: tenant,
      tenantLanguage: tenantLanguage,
    });
    let idSessionTab = sessionStorage.getItem("sessions");
    if (!idSessionTab) idSessionTab = sessionStorage.setItem("sessions", Math.floor(Math.random() * 1e16).toString().padStart(16, '0'));
    idSessionTab = sessionStorage.getItem("sessions");
    //const responseIP = await axios.get("https://api.ipify.org?format=json");
    const responseIP = { ip: "127.0.0.2" };
    if (responseIP.ip && infoNavigator) {
      await axios
        .post(API_AUTHENTICATION + "/api/Login", {
          ...userData,
          ip: responseIP.ip,
          Browser: infoNavigator,
          TypeDeviceId: 2,
          idSessionTab: idSessionTab
        }, myConfig)
        .then(async (response) => {
          const { data } = response;
          if (data.statusCode === 200) {
            const { urlApp } = data.result;
            if (urlApp === undefined) {
              console.error(
                "Ha ocurrido un error. No se encuentra definida la url a redireccionar."
              );
            } else {
              //window.location.assign(urlApp);
              dispatch({
                type: SET_IS_AUTHENTICATED,
                payload: true,
              });
            }
          }
          datadispatch = authResponse2(
            response.status,
            data.statusCode,
            "A10",
            0,
            data.result.information,
            data.result.urlApp,
            data.result.informationCode,
            data.result.ententerpriseList,
            data.result.token,
            data.result.failedLoginInformation,
            data.result.isBlocked,
            data.result.messageParameter
          );
        })
        .catch((error) => {
          const { response } = error;
          if (response) {
            try {
              const { data } = response;
              const { informationCode, information } = data.result;
              if (informationCode === "R14") {
                //Se debe cambiar la clave antes de su primer acceso
                if (history) {
                  history.push({
                    pathname: "/Alert",
                    state: {
                      message: !!information
                        ? information
                        : messageActionCheckWelcome !== '' ? messageActionCheckWelcome : "Por favor revise el correo de bienvenida que contiene el enlace y las instrucciones para activación de su usuario.",
                      pagename: "/Login",
                      messageType: ERRORINFO,
                    },
                  });
                }
              } else if (informationCode === "R18") {
                //Se debe cambiar la clave Se encuentra vencida
                if (history) {
                  history.push({
                    pathname: "/Alert",
                    state: {
                      message: !!information
                        ? information
                        : messageActionPasswordHasExpired !== '' ? messageActionPasswordHasExpired : "Su clave se encuentra venciada debe realizar el cambio.",
                      pagename: data.result.urlApp,
                      messageType: ERRORINFO,
                    },
                  });
                }
              } else {
                //Se retorna el mensaje
                datadispatch = authResponse(
                  response.status,
                  !!data.statusCode ? data.statusCode : "500",
                  data.result.informationCode,
                  data.result.blockingtime,
                  !!data.result.information && data.statusCode !== 500
                    ? data.result.information
                    : DEFAULTRESPONSE.noResponseFromApi,
                  data.result.failedLoginInformation,
                  data.result.isBlocked,
                  data.result.messageParameter
                );
              }
            } catch {
              datadispatch = authResponse(
                500,
                500,
                "",
                0,
                DEFAULTRESPONSE.noResponseFromApi
              );
            }
          } else {
            datadispatch = authResponse(
              500,
              500,
              "",
              0,
              DEFAULTRESPONSE.noResponseFromApi
            );
          }
        })
        .then(function () {
          changeLoading(dispatch, false);
          dispatch({
            type: SET_CURRENT_USER,
            payload: datadispatch,
          });
        });
    }
  };

export /**
 * AuthAction resetPassword ( Reset Password  )
 *
 * @method POST
 * @param {history} history
 */
  const resetPassword = (userData, history, previousTabHistory = '', tenant = '') => async (dispatch) => {
    let tenantLanguage = '';
    if (userData.appname === 'license' && tenant !== null && tenant !== undefined && tenant !== '') {
      tenantLanguage = localStorage.getItem("bms_lang");
      if (!tenantLanguage) {
        tenantLanguage = "es";
      } else {
        tenantLanguage = JSON.parse(tenantLanguage);
      }
      tenantLanguage = tenantLanguage.toUpperCase();
    }
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      tenant: tenant,
      tenantLanguage : tenantLanguage,
    });
    let datadispatch = {};
    changeLoading(dispatch, true);
    await axios
      .post(API_AUTHENTICATION + "/api/login/ResetPassword", userData, myConfig)
      .then((response) => {
        const { data } = response;
        if (history)
          history.push({
            pathname: getComponentValue(userData.appname, 'componentSendEmailResetPassword', 'relativePath'),
            state: {
              message: data.result.information,
              maskedEmail: data.result.maskedEmail,
              pagename: "/Login",
              messageType: CHECKEMAIL,
              previousTabHistory
            },
          });
          dispatchUsers(undefined , dispatch); // Clear when user email has been reset
      })
      .catch((error) => {
        dispatchUsers(undefined , dispatch); // Likewise if the request fails
        const { response } = error;
        if (response) {
          try {
            const { data } = response;

            datadispatch = authResponse(
              response.status,
              !!data.statusCode ? data.statusCode : "500",
              data.result.informationCode,
              data.result.blockingtime,
              !!data.result.information && data.statusCode !== 500
                ? data.result.information
                : DEFAULTRESPONSE.noResponseFromApi,
              data.result.failedLoginInformation
            );
          } catch {
            datadispatch = authResponse(
              500,
              500,
              "",
              0,
              DEFAULTRESPONSE.noResponseFromApi
            );
          }
        } else {
          datadispatch = authResponse(
            500,
            500,
            "",
            0,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      })
      .then(function () {
        changeLoading(dispatch, false);
        dispatch({
          type: SET_CURRENT_USER,
          payload: datadispatch,
        });
      });
  };

export const clearAuthenticationResponse = () => async (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: undefined,
  });
};

export /**
 * AuthAction assignPassword ( assign Password  )
 *
 * @method POST
 * @param {history} history
 */
  const assignPassword = (userData, history, tenant = '') => async (dispatch) => {
    let tenantLanguage = '';
    const originalPassword = userData.password;

    //Cifra contenido de password
    var passwordEncrypt = encryptAes(userData.password);
    userData.password = passwordEncrypt;

    if (userData.appname === 'license' && tenant) {
      tenantLanguage = localStorage.getItem("bms_lang");
      if (!tenantLanguage) {
        tenantLanguage = "es";
      } else {
        tenantLanguage = JSON.parse(tenantLanguage);
      }
      tenantLanguage = tenantLanguage.toUpperCase();
    }
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      tenant: tenant,
      tenantLanguage : tenantLanguage,
    });
    return new Promise(async (resolve, reject) => {
      let datadispatch = {};
      let isUserActivation = false;
      if (userData.isUserActivation !== null || userData.isUserActivation !== undefined) {
        isUserActivation = userData.isUserActivation;
      }
      changeLoading(dispatch, true);
      try {
        const response = await axios.post(API_AUTHENTICATION + "/api/login/AssignPassword", userData, myConfig);
        const { data } = response;
        if (data.result.apps !== null) {
          if (history) {
            history.push({
              pathname: "/availableapps",
              state: {
                message: data.result.information,
                apps: data.result.apps,
                userData: { appname: userData.appname, page: "", password: originalPassword, user: userData.user },
              },
            });
          }
        } else {
          if (data.result.mechanismAfterSetPass === 1) {
            if (history) {
              history.push({
                pathname: "/Alert",
                state: {
                  message: data.result.information,
                  pagename: "/Login",
                  messageType: isUserActivation ? ASSIGNINITIALPASSWORD : PASSWORDUPDATED
                },
              });
            }
          } else {
            if (history) {
              history.push({
                pathname: "/Alert",
                state: {
                  message: data.result.information,
                  pagename: "/Login",
                  messageType: isUserActivation ? ASSIGNINITIALPASSWORD : PASSWORDUPDATED,
                },
              });
              dispatch({
                type: IS_LOGIN_AUTOMATIC,
                payload: true,
              });
              dispatch(
                loginUser({ appname: userData.appname, page: "", password: originalPassword, user: userData.user }, history)
              );
            }
          }
        }
        changeLoading(dispatch, false);
        dispatch({
          type: SET_CURRENT_USER,
          payload: datadispatch,
        });
        resolve(response);
      } catch (error) {
        const { response } = error;
        console.error(response);
        if (response) {
          const { data } = response;
          datadispatch = authResponse(
            response.status,
            !!data.statusCode ? data.statusCode : "500",
            data.result.informationCode,
            data.result.blockingtime,
            !!data.result.information && data.statusCode !== 500
              ? data.result.information
              : DEFAULTRESPONSE.noResponseFromApi
          );
        } else {
          datadispatch = authResponse(
            500,
            500,
            "",
            0,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
        changeLoading(dispatch, false);
        dispatch({
          type: SET_CURRENT_USER,
          payload: datadispatch,
        });
        reject(error);
      }
    });
  };

export /**
 * AuthAction changePassword ( Change Password  )
 *
 * @method POST
 * @param {history} history
 */
  const changePassword = (userData, history) => async (dispatch) => {
    let datadispatch = {};
    changeLoading(dispatch, true);

    //Cifra contenido de password
    var passwordEncrypt = encryptAes(userData.password);
    userData.password = passwordEncrypt;

    var newpasswordEncrypt = encryptAes(userData.newpassword);
    userData.newpassword = newpasswordEncrypt;

    await axios
      .post(API_AUTHENTICATION + "/api/login/ChangePassword", userData)
      .then((response) => {
        const { data } = response;

        if (history)
          history.push({
            pathname: "/Alert",
            state: {
              message: data.result.information,
              pagename: "/Login",
            },
          });
      })
      .catch((error) => {
        const { response } = error;
        console.error(response);
        if (response) {
          const { data } = response;
          datadispatch = authResponse(
            response.status,
            !!data.statusCode ? data.statusCode : "500",
            data.result.informationCode,
            data.result.blockingtime,
            !!data.result.information && data.statusCode !== 500
              ? data.result.information
              : DEFAULTRESPONSE.noResponseFromApi
          );
        } else {
          datadispatch = authResponse(
            500,
            500,
            "",
            0,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      })
      .then(function () {
        changeLoading(dispatch, false);
      });
    dispatch({
      type: SET_CURRENT_USER,
      payload: datadispatch,
    });
  };

export /**
  * AuthAction SendEmailActivationPassword (  Send Email  )
  *
  * @method POST
  * @param {history} history
  */
  const SendUserActivationEmail = (userData, tenant = '') => async (dispatch) => {
    let tenantLanguage = '';
    if (userData.appname === 'license' && tenant !== null && tenant !== undefined && tenant !== '') {
      tenantLanguage = localStorage.getItem("bms_lang");
      if (!tenantLanguage) {
        tenantLanguage = "es";
      } else {
        tenantLanguage = JSON.parse(tenantLanguage);
      }
      tenantLanguage = tenantLanguage.toUpperCase();
    }
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      tenant: tenant,
      tenantLanguage : tenantLanguage,
    });
    changeLoading(dispatch, true);
    await axios
      .post(API_AUTHENTICATION + "/api/user/SendUserActivationEmail", userData, myConfig)
      .then((response) => {
        const { data } = response;
        if (!!response.data.statusDetails && response.data.statusDetails.length > 0) {
          dispatch({
            type: SEND_ACTIVATION_EMAIL,
            payload: { code: response.data.statusDetails[0].code, message: data.result },
          });
        }
      })
      .catch((error) => {
        const { response } = error;
        if (!!response.data.statusDetails && response.data.statusDetails.length > 0) {
          dispatch({
            type: SEND_ACTIVATION_EMAIL,
            payload: { code: response.data.statusDetails[0].code, message: response.data.statusDetails[0].description },
          });
        }
      })
  };

export /**
 * AuthAction tokenValidation ( token Validation )
 *
 * @method GET
 * @param {history} history
 */
  const tokenValidation = (user, token, currentApp, history, option) => async (dispatch) => {
    const path = history.location.pathname;

    try {
      const requestData = {
        headers: {
          user: user,
          token: token,
          option: option,
          currentApp
        },
      };
      const response = await axios.get(
        API_AUTHENTICATION + "/api/login/TokenValidation",
        requestData
      );
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.result,
      });

      if (option !== undefined && response.status === 200) {
        history.push({
          pathname:
            path.toLowerCase() !== "/login" && path !== "/" ? path : "/users",
        });
        dispatch({
          type: SET_IS_AUTHENTICATED,
          payload: true,
        });
      }
    } catch (error) {
      const { response } = error;
      console.error(response);
      if (response) {
        const { data } = response;
        if (history) {
          var message = data
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi;
          if (option === 1) {
            localStorage.clear();
            history.push({
              pathname: "/login",
            });
          } else {
            if (data.result.isUserActivation) {
              dispatch({
                type: IS_INVALID_TOKEN,
                payload: {
                  ok: true,
                  message: message
                },
              });
            } else {
              history.push({
                pathname: "/Alert",
                state: {
                  message: message,
                  pagename: "/Login",
                },
              });
            }
          }
        }
      }
    }
  };

export /**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
  const logoutUser = (history) => async () => {
    assignAuthTokenAndRefreshToken(false);
    if (history)
      history.push({
        pathname: "/login",
        state: { message: "Sesión finalizada exitosamente" },
        username: JSON.parse(sessionStorage.getItem("userTokenData")).name,
      });

    sessionStorage.removeItem("userTokenData");
  };

function authResponse(
  httpCode,
  responseCode,
  infoCode,
  userTime,
  message,
  failedLoginInformation,
  isBlocked,
  messageParameter
) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    message: message,
    responseStat: infoCode,
    timeUser: userTime,
    failedLoginInformation: failedLoginInformation,
    isBlocked: isBlocked,
    messageParameter: messageParameter
  };
}

function authResponse2(
  httpCode,
  responseCode,
  infoCode,
  userTime,
  message,
  url,
  code,
  enterprise,
  token,
  failedLoginInformation,
  isBlocked,
  messageParameter
) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    responseStat: infoCode,
    timeUser: userTime,
    message: message,
    url,
    code: code,
    enterprise: enterprise,
    token: token,
    failedLoginInformation: failedLoginInformation,
    isBlocked: isBlocked,
    messageParameter: messageParameter
  };
}

function changeLoading(dispatch, status) {
  dispatch({
    type: LOADING,
    payload: status,
  });
}

/**
 * validateTokenAction Validar token
 *
 * @method validateTokenAction
 * @param {queryString} queryString
 */
export const validateTokenAction = (queryString) => async (dispatch) => {
  try {
    assignAuthTokenAndRefreshToken(false);
    if (!!queryString) {
      const tokenAndRefreshToken = await decodeTokenData(queryString);
      await loginTokenValidation(tokenAndRefreshToken, dispatch);
    }
  } catch (err) {
    console.error(err);
    redirectToLogin();
  } finally {
    // detener loading
  }
};

/**
 * AuthAction loginTokenValidation ( set user credentials to server for login attempt )
 *
 * @method GET
 * @param {*} userData
 */
async function loginTokenValidation(tokenAndRefreshToken, dispatch) {
  try {
    const token = tokenAndRefreshToken.token;
    const decodedTokenJwt = jwt_decode(token);
    if (tokenValidity(decodedTokenJwt)) {
      const userTokenData = {
        userId: decodedTokenJwt.jti,
        nameid: decodedTokenJwt.nameid,
        name: decodedTokenJwt.given_name.toLowerCase(),
        surname: decodedTokenJwt.family_name.toLowerCase(),
        customerName: decodedTokenJwt.customer_name.toLowerCase(),
        privileges: decodedTokenJwt.IBUHOO_PRIVILEGE,
      };
      assignAuthTokenAndRefreshToken(tokenAndRefreshToken);
      dispatch({
        type: GET_USER_DATA,
        payload: userTokenData,
      });
      sessionStorage.setItem("userTokenData", JSON.stringify(userTokenData));
    } else {
      redirectToLogin();
    }
  } catch (error) {
    const { response } = error;

    if (response) {
      console.error(error);
      redirectToLogin();
    }
  }
}

/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || "", "base64").toString("hex")
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);

    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      const tokens = {
        token: paramsObj.token,
        refreshToken: paramsObj.refreshToken,
        encryptedToken: queryString,
      };
      return tokens;
    } else {
      redirectToLogin();
    }
  } catch (err) {
    console.error(err);
    redirectToLogin();
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\000-\031\200-\377]+/g, "");
    return paramsChar;
  } catch (err) {
    console.error("Error limpiando parametros:", err);
  }
}

/**
 * validar vigencia del token
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const isValidToken = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    return tokenValidity(decodedTokenJwt);
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * valida que la vigencia del token se mayor o igual a 20 segundos
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const tokenValidationTime = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedTokenJwt.exp).utc();
    return (
      moment(dateExpiresToken).diff(currentDate, "seconds") < TIME_REFRESH_TOKEN
    );
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * Invoca el api de refreshToken y retorna un nuevo token
 * @param {refreshData} refreshData
 */
export const refreshToken = (refreshData) => async () => {
  try {
    await axios
      .post(API_AUTHENTICATION + "/api/Login/RefreshToken", refreshData)
      .then((response) => {
        assignAuthTokenAndRefreshToken(response.data.result.token);
      });
  } catch (err) {
    console.error("Error refrescando Token:", err);
  }
};

/**
 * validar vigencia del token
 *
 * @method tokenValidity
 * @param {querytokenString} token
 */
export const tokenValidity = (decodedToken) => {
  try {
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();
    return moment(dateExpiresToken).isAfter(currentDate);
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * Redireccionar a login
 *
 * @method redirectToLogin
 * @param
 */
export async function redirectToLogin() {
  await logoutUser();
}

export const generateTokens = (body) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: body === undefined ? "" : body.token,
      },
    };

    const res = await axios.post(
      API_AUTHENTICATION + "/api/login/GenerateTokens",
      body,
      headers
    );
    dispatch({
      type: GENERATE_TOKENS,
      payload: res.data.result,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

/**
 * @method revokeTokenUsers
 * remueve el token de el localStorage consulta el servicio de removeToken para invalidar el token
 * y redirecciona a la vista de login
 * @author kevin.olarte
 */
export const revokeTokenUsers = (setLoading) => async (dispatch, getState) => {
  try {
    let revokeToken = {
      UserName: JSON.parse(sessionStorage.getItem("userTokenData")).nameid,
      Quantity: 1,
      AppName: JSON.parse(localStorage.getItem("_appName")),
      RefreshToken: sessionStorage.getItem("refreshToken")
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const res = await axios.put(
      API_AUTHENTICATION + "/api/login/RevokeToken",
      revokeToken,
      headers
    );
    if (res.status === 200) {
      dispatch({
        type: REVOKE_TOKENS,
        payload: res.data.result,
      });
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("userTokenData");
      sessionStorage.removeItem("encryptedToken");
      const { data } = res;
      window.location.href = data.result.information;
    } else {
      return false;
    }
    setLoading(false);
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    };
    let response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/documentType/GetDocumentTypesLogin", config
    );

    if (response.status === 200) {
      if (response.data.result.records.result.count > 0) {
        dispatch({
          type: GET_DOCUMENT_TYPES,
          payload: response.data.result.records.result.records,
        });
      }
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getExistsUsersAccount = (data) => async (dispatch) => {
  try {
    changeLoading(dispatch, true);
    dispatch({ type: LOADING_GET_USERS_ACCOUNT, payload: true });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        documentType: data.typeDocument,
        documentNumber: data.numberDocument,
      },
    };
    let response = await axios.get(
      API_AUTHENTICATION + "/api/login/GetExistsUsersAccount", config
    );

    if (response.status === 200) {
      dispatch({
        type: GET_EXISTS_USERS_ACCOUNT,
        payload: response.data.result,
      });
      changeLoading(dispatch, false);
      dispatch({ type: LOADING_GET_USERS_ACCOUNT, payload: false });
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const checkIsValidEmailAction = (email) => async (dispatch) => {
  try {
    changeLoading(dispatch, true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/send-otp-user", email, config,
    );
    dispatch({
      type: VALIDITY_USER_EMAIL,
      payload: {
        response: response.data.result,
        statusCode: response.data.statusCode
      },
    });
    changeLoading(dispatch, false);
  } catch (error) {
    dispatch({
      type: VALIDITY_USER_EMAIL,
      payload: {
        response: error.response,
        statusCode: error.response.data.statusCode,
      },
    });
  }
};

export const validateMailCodeAction = (email, code) => async (dispatch) => {
  try {
    changeLoading(dispatch, true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const validityCodeRequest = {
      Email: email,
      OtpCode: code,
    };
    const response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/validate-otp-user", validityCodeRequest, config,
    );
    dispatch({
      type: VALIDITY_EMAIL_CODE,
      payload: {
        response: response.data.result,
        statusCode: response.data.statusCode
      },
    });
    changeLoading(dispatch, false);
  } catch (error) {
    dispatch({
      type: VALIDITY_EMAIL_CODE,
      payload: {
        response: error.response,
        statusCode: error.response.data.statusCode,
      },
    });
  }
};

export const ResetPasswordUserCertificateAction = (userName) => async (dispatch) => {
  try {
    changeLoading(dispatch, true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const validityCodeRequest = {
      UserName: userName,
    };
    const response = await axios.post(
      API_AUTHENTICATION + "/api/login/resetPasswordCertificateUser", validityCodeRequest, config,
    );
    dispatch({
      type: RESET_PASSWORD_USER_CERTIFICATE,
      payload: {
        response: response.data.result,
        statusCode: response.data.statusCode
      },
    });
    changeLoading(dispatch, false);
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_USER_CERTIFICATE,
      payload: {
        response: error.response,
        statusCode: error.response.data.statusCode,
      },
    });
  }
};


export const dispatchUsers = (data , dispatch) => {
  dispatch({
    type: GET_USER_LIST,
    payload: {
      data, 
    },
  });
}

/**
 * Call a list of users for unique email 
 * @param {*} user 
 * @param {*} appName 
 */
export const getUserForResetPassword = (data, tenant = '') => async (dispatch) =>  {
  let tenantLanguage = '';
  if (data.appname === 'license') {
    if (!tenant) {
      tenant = 'myselfcert';
    }
    tenantLanguage = localStorage.getItem("bms_lang");
    if (!tenantLanguage) {
      tenantLanguage = "es";
    } else {
      tenantLanguage = JSON.parse(tenantLanguage);
    }
    tenantLanguage = tenantLanguage.toUpperCase();
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  Object.assign(config.headers, {
    tenant: tenant,
    tenantLanguage : tenantLanguage,
  });
  try {
    const response = await axios.post(
      API_AUTHENTICATION + "/api/login/GetUsersResetPassword", data, config,
    );
    dispatchUsers(response.data.result.users , dispatch);
  } catch (error) {
    dispatchUsers(error.response.data.result.information, dispatch);
  }
}

export const GenerateTokenOIDCAction = (code, tenant = '', appName) => async (dispatch) => {
  try {
    changeLoading(dispatch, true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const validityCodeRequest = {
      code,
      tenant,
      appName,
      tenantLanguage: '',
    };
    const response = await axios.post(
      API_AUTHENTICATION + "/api/login/generate-token-oidc", validityCodeRequest, config,
    );
    
    dispatch({
      type: SET_TOKEN_OAUTH,
      payload: {
        response: response.data.result,
        statusCode: response.data.statusCode
      },
    });
    changeLoading(dispatch, false);
  } catch (error) {
    console.warn(error);
    dispatch({
      type: SET_ERROR_TOKEN_OAUTH,
      payload: {
        message: "Error",
      },
    });
    changeLoading(dispatch, false);
  }
};

export const ConnectCiamOidcAction = (appName, tenant = 'bmscol', tenantLanguage = 'ES') => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GET_USERS_ACCOUNT, payload: true });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    Object.assign(config.headers, {
      appName: appName,
      tenant : tenant,
      tenantLanguage: tenantLanguage,
    });
    const response = await axios.get(
      API_AUTHENTICATION + "/api/login/connect-ciam-oidc", config,
    );
    if (response.data.statusCode === 200) {
      dispatch({
        type: SET_PARAMS_CONNECT_CIAM_OIDC,
        payload: {
          response: response.data.result,
        },
      });
      dispatch({ type: LOADING_GET_USERS_ACCOUNT, payload: false });
    }
    changeLoading(dispatch, false);
  } catch (error) {
    console.log(error);
    changeLoading(dispatch, false);
  }
};