import React, { Fragment, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import MasterPage from "../components/masterPage/masterPage.component.js";
import { validateTokenAction, tokenValidation } from "../actions/auth.action";
import AlertConfirm from "../components/alertConfirm.component";
import { useHistory } from 'react-router'
import * as signalR from '@microsoft/signalr';
import { API_SECURITY_ENDPOINT, API_SOCKET_ENDPOINT } from "../config/config";
/**
 * PrivateRoute function (Intercepts all private routes with token verification service consumption,
 * on success and valid response, return a React Fragment with component and sidenav,
 * on fail, return a login redirect, on loading return a centered spinner)
 *
 * @export function
 * @param {Component} PrivateRoute
 * @param {String} roleNames
 * @param {String} location
 * @returns React.Fragment, Redirect 
 */

const PrivateRoute = ({ component: Component, isAuth, ...rest }, props) => {
  const [connectionId, setConnectionId] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isCloseSession, setIsCloseSession] = useState(false);
  const [isCloseSessionMessage, setIsCloseSessionMessage] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [_, setOpen] = useState(false);

  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    // const appName = rest.appName;
    const paramToken = window.location.search.substring(1);

    if (!paramToken && !jwtToken) {
      return <Redirect to='/login' />
    }
    if (paramToken && !jwtToken) {
      dispatch(validateTokenAction(paramToken));
      history.replace({
        search: "",
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    const options = {
        transport,
        skipNegotiation: true,
        logMessageContent: true,
    };

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${API_SOCKET_ENDPOINT}/api/connections/session`, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();

        connection.start()
        .then(() => {
            setConnectionId(connection.connectionId);
            setIsConnected(true);

            connection.invoke("GetConnectionId").then(connectionId => {
                setConnectionId(connectionId);
            });

            connection.on('ReceiveSessionStatus', message => {
                setIsCloseSession(true);
                setIsCloseSessionMessage(message.message);
                connection.stop().then(() => {
                  setTimeout(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = '/';
                  }, 2000);
                  console.log("Cierra la conexión del websocket");
                }).catch(err => {
                    console.error('Error closing connection', err);
                });
            });
        })
        .catch(e => console.log('Connection failed: ', e));
  }, []);

  useEffect(() => {
    if (connectionId && isConnected && !isValidated) {

      const timeoutId = setTimeout(() => {
        const token = sessionStorage.getItem("jwtToken");
        
        const validateSession = async () => {
          try {
            await fetch(`${API_SECURITY_ENDPOINT}/api/user/validatesession`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'connectionId': connectionId,
              }
            });
            setIsValidated(true);
          } catch (e) {
            console.error('Error al validar la sesión:', e);
          }
        };

        validateSession();
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [connectionId, isConnected, isValidated]);

  return (
    <>
      <Route
        {...rest}
        render={(componentProps) => (
          <Fragment>
            <MasterPage component={Component} {...componentProps} appName={rest.appName} />
          </Fragment>
        )
        }
      />
      {isCloseSession && (
        <AlertConfirm
          isOpen={isCloseSession}
          modalMessage={isCloseSessionMessage}
          showBtnClose={false}
          showBtnAccept={false}
          setOpen={setOpen}
          widthTitle="100%"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, { validateTokenAction, tokenValidation })(PrivateRoute);
